import React from 'react'

export const IconTwitter = () => {
  return (
    <svg
      viewBox="0 0 16 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.086.222a6.236 6.236 0 0 1-1.955.735A3.089 3.089 0 0 0 10.885 0c-1.7 0-3.077 1.357-3.077 3.03 0 .237.027.469.079.69A8.781 8.781 0 0 1 1.545.554a2.974 2.974 0 0 0-.416 1.523c0 1.052.544 1.979 1.368 2.522a3.117 3.117 0 0 1-1.393-.381v.038c0 1.468 1.061 2.692 2.468 2.972a3.18 3.18 0 0 1-1.39.051 3.074 3.074 0 0 0 2.874 2.105 6.24 6.24 0 0 1-3.822 1.296c-.248 0-.493-.014-.734-.041A8.814 8.814 0 0 0 5.217 12c5.66 0 8.755-4.617 8.755-8.621l-.01-.393A6.153 6.153 0 0 0 15.5 1.42a6.246 6.246 0 0 1-1.767.477A3.048 3.048 0 0 0 15.086.222z"
        fillRule="evenodd"
      />
    </svg>
  )
}
