import React from "react"

export const StatusIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
    <path
      fillRule="evenodd"
      d="M17 10h-1.101a6.918 6.918 0 0 1-1.31 3.175l.775.775a1 1 0 0 1-1.414 1.414l-.775-.775A6.918 6.918 0 0 1 10 15.899V17a1 1 0 0 1-2 0v-1.101a6.918 6.918 0 0 1-3.175-1.31l-.775.775a1 1 0 0 1-1.414-1.414l.775-.775A6.918 6.918 0 0 1 2.101 10H1a1 1 0 0 1 0-2h1.101a6.918 6.918 0 0 1 1.31-3.175l-.775-.775A.999.999 0 1 1 4.05 2.636l.775.775A6.918 6.918 0 0 1 8 2.101V1a1 1 0 0 1 2 0v1.101a6.918 6.918 0 0 1 3.175 1.31l.775-.775a1 1 0 0 1 1.414 1.414l-.775.775A6.918 6.918 0 0 1 15.899 8H17a1 1 0 0 1 0 2zM9 4a5 5 0 1 0 .001 10.001A5 5 0 0 0 9 4zm0 8a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
    />
  </svg>
)
